import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

const Icon = ({ name }) => {
  switch (name) {
    case "external":
      return (
        <FontAwesomeIcon
          icon={faArrowUpRightFromSquare}
          size="sm"
          style={{ marginRight: "2px" }}
        />
      );
    case "global":
      return <FontAwesomeIcon icon={faGlobe} style={{ marginRight: "2px" }} />;
    default:
      return "icon error";
  }
};

export default Icon;
