import "./App.css";
import { useState } from "react";
import InfoCard from "./component/infoCard";
import Icon from "./component/icon";

function App() {
  const [language, setLanguage] = useState("English");
  const [buttonText, setButtonText] = useState("前往新網站");

  const switchLanguage = () => {
    setLanguage((prevLanguage) =>
      prevLanguage === "English" ? "繁體中文" : "English"
    );
    setButtonText((buttonText) =>
      language === "繁體中文" ? "前往新網站" : "Access New Site"
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <img src="./BXLogoT.png" alt="baixian logo" className="home-logo" />
      </div>
      <div className="centerDiv">
        <InfoCard language={language} />
        <div className="center">
          <a
            href="https://baixian-group.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ margin: "20px" }}>
            <Icon name="external" /> {buttonText}
          </a>
          <button onClick={switchLanguage}>
            {" "}
            <Icon name="global" /> {language}
          </button>{" "}
        </div>
      </div>
    </div>
  );
}

export default App;
